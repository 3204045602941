import React, { useEffect, useState, useRef } from "react";

import about from "../../Assets/team.jpg";

import { ServiceSection } from "../Sections/ServiceCard/ServiceCard";

import { FAQs } from "../Sections/FAQ/FAQ";
import { Article } from "../Sections/Articles/Articles";
import { TakeCare } from "../Sections/TakeCare/TakeCare";
import { BookAppointment, FreeEstimateButton } from "../Sections/Button/Button";
import { HelmetSEO } from "../../HelmetSEO";

function About() {
  return (
    <>
      <HelmetSEO
        title="Friseur - Über uns"
        description="Friseur Katharinas bietet Top-Haarstyling durch Katharina und Team. Erleben Sie Qualität, Präzision und individuelle Beratung für Ihren Traumlook."
        url="https://www.friseur-katharinas.de/about-us"
      />
      <div>
        <div class="angle-30 angled-bg">
          <div
            class="no_angle_bg_img"
            style={{ backgroundImage: "url('')" }}
          ></div>
        </div>

        <div className="border-b-8 border-lightpink">
          <div className="container mx-auto ">
            <h1 className="font-extrabold text-4xl pt-16 pb-8 text-center text-pink-600">
              Über uns
            </h1>
            <div className="py-12 px-4 grid md:grid-cols-2 gap-8 items-center">
              <div class="aspect-w-16 aspect-h-9">
                <img
                  src={about}
                  alt=""
                  className="w-full h-auto max-h-[500px]"
                />
              </div>
              <div className="space-y-4">
                <h1 className="text-3xl font-bold">Unsere Geschichte</h1>
                <p>
                  Mein Name ist Katharina und ich bin stolz darauf, eine
                  ausgebildete Friseurmeisterin zu sein. Zusammen mit meinem
                  erfahrenen Team stehen wir bereit, um deine Frisurenträume
                  Wirklichkeit werden zu lassen. Dank unserer langjährigen
                  Erfahrung und unserer Leidenschaft für das Friseurhandwerk
                  können wir uns voll und ganz auf deine Wünsche und Bedürfnisse
                  konzentrieren. Wir streben stets nach höchster Qualität und
                  möchten sicherstellen, dass du mit deinem Styling auf höchstem
                  Niveau zufrieden bist. Komm vorbei und lass dich von unserem
                  talentierten Team verwöhnen – wir freuen uns darauf, dich
                  strahlen zu sehen!
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="border-b-8 border-lightpink pb-8">
          <div className="container mx-auto px-4">
            <section className="mt-12">
              <div className="p-2 lg:p-4">
                <div className="container mx-auto space-y-8">
                  <h1 className="font-bold text-4xl text-pink-600">
                    Wir stehen für Qualitätsservice
                  </h1>
                  <p>
                    In unserem Salon verpflichten wir uns zur Exzellenz in jedem
                    Schnitt, jeder Farbe und jedem Wellness-Angebot. Qualität
                    ist unsere Signatur – erleben Sie herausragende
                    Dienstleistungen, die mit Fachwissen und Sorgfalt in unserem
                    Friseur- und Spa-Salon ausgeführt werden. Wir sind nicht nur
                    ein Salon, wir sind Ihr Partner für Schönheit und
                    Entspannung, der stets nach Perfektion strebt.
                  </p>

                  <BookAppointment />
                </div>
              </div>
            </section>
          </div>
        </div>

        <div className="container mx-auto px-4">
          <section className="mt-12">
            <div className="px-2 py-8">
              <div className="container mx-auto">
                <h1 className="font-bold text-4xl mb-8 text-center text-pink-600">
                  Alles, was wir anbieten
                </h1>
                <ServiceSection />
              </div>
            </div>
          </section>
        </div>

        <FAQs />

        {/* <Article /> */}

        <TakeCare />
      </div>
    </>
  );
}

export default About;
