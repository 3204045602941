import { Link } from "react-router-dom";

export const FreeEstimateButton = () => (
  <div className="mt-8 flex">
    <button
      className="h-12 relative bg-customgreen text-white font-bold text-xl hover:bg-green-700 flex items-center justify-center group"
      type="submit"
    >
      <span className="w-full flex-auto text-center px-4">
        GET A FREE ESTIMATE
      </span>
      <span className="h-full right-0 inset-y-0 bg-green-500 flex items-center justify-center w-16 group-hover:bg-green-600">
        <i className="fas fa-chevron-right text-white"></i>
      </span>
    </button>
  </div>
);

export const ReachOut = () => (
  <div className="mt-8 flex">
    <button
      className="h-12 relative bg-customgreen text-white font-bold text-xl hover:bg-green-700 flex items-center justify-center group"
      type="submit"
    >
      <span className="w-full flex-auto text-center px-4">REACH OUT</span>
      <span className="h-full right-0 inset-y-0 bg-green-500 flex items-center justify-center w-16 group-hover:bg-green-600">
        <i className="fas fa-chevron-right text-white"></i>
      </span>
    </button>
  </div>
);

export const ReadMore = () => (
  <div className="mt-8 flex">
    <Link to="/about-us">
      <button className="h-12 relative bg-pink-600 text-white hover:bg-pink-700 flex items-center justify-center group">
        <span className="w-full flex-auto text-center px-4">Mehr lesen</span>
        <span className="h-full right-0 inset-y-0 bg-pink-700 flex items-center justify-center w-16 group-hover:bg-pink-800">
          <i className="fas fa-chevron-right text-white"></i>
        </span>
      </button>
    </Link>
  </div>
);

export const BookAppointment = () => (
  <div className="flex">
    <Link
      to="https://www.time-globe-crs.de/crs-online-7354/"
      target="_blank"
      className="h-12 my-auto relative bg-pink-600 text-white font-bold hover:bg-pink-700 flex items-center justify-center group"
    >
      <span className="w-full flex-auto text-center px-4">
        Einen Termin Vereinbaren
      </span>
      <span className="h-full right-0 inset-y-0 bg-pink-700 flex items-center justify-center w-16 group-hover:bg-pink-800">
        <i className="fas fa-chevron-right text-white"></i>
      </span>
    </Link>
  </div>
);
