import React, { useState } from "react";
import work1 from "../../../Assets/work/work1.jpg";

const faqsData = [
  {
    question: "Kindergeburtstag",
    answer:
      "Verwandeln Sie Kindergeburtstage in ein unvergessliches Erlebnis mit speziellen Frisuren und Styling, die speziell für die kleinen Gäste konzipiert sind. Unser Team sorgt mit Spaß und Kreativität für strahlende Kindergesichter.",
  },
  {
    question: "Makeup Seminar",
    answer:
      "Entdecken Sie die Kunst des Makeups in unseren professionellen Makeup-Seminaren. Lernen Sie Techniken für den Alltag oder besondere Anlässe, unter Anleitung unserer Experten für ein perfektes Finish.",
  },
  {
    question: "Styling Seminar",
    answer:
      "Unsere Styling-Seminare bieten tiefe Einblicke in die Welt der Haar- und Modebranche. Erhalten Sie professionelle Tipps und Tricks, um individuelle Looks zu kreieren, die Persönlichkeit und Stil unterstreichen.",
  },
];

export const FAQs = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    if (openIndex === index) {
      setOpenIndex(null); // If clicked again, close it
    } else {
      setOpenIndex(index); // Else, open the clicked one
    }
  };

  return (
    <div className="container mx-auto">
      <div className="py-12 px-4">
        <div className="grid md:grid-cols-2 gap-8 items-center">
          <div class="aspect-w-16 aspect-h-9">
            <div className="max-w-2xl mx-auto">
              <h2 className="text-4xl font-bold mb-8">Hair & Beauty Kurse</h2>
              {faqsData.map((faq, index) => (
                <div key={index} className="mb-2">
                  <button
                    onClick={() => toggleFAQ(index)}
                    className="flex justify-between items-center w-full px-4 py-4 text-left text-white bg-lightpink rounded-t-md hover:bg-pink-500"
                  >
                    <span>{faq.question}</span>
                    <span>{openIndex === index ? "▲" : "▼"}</span>
                  </button>
                  {openIndex === index && (
                    <div className="px-4 pt-4 pb-2 text-sm bg-white text-black rounded-b-md">
                      {faq.answer}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className="space-y-2">
            <img
              src={work1}
              alt=""
              className="rounded-sm w-full h-auto max-h-[500px]"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
