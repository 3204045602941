import React, { useEffect, useState, useRef } from "react";

import { TakeCare } from "../Sections/TakeCare/TakeCare";
import { HelmetSEO } from "../../HelmetSEO";

const Section = ({ title, children }) => (
  <div className="my-8">
    <h2 className="text-xl font-bold mb-4">{title}</h2>
    <p>{children}</p>
  </div>
);

function Imprint() {
  return (
    <>
      <HelmetSEO
        title="Friseur - Impressum"
        description=""
        url="https://www.friseur-katharinas.de/imprint"
      />
      <div>
        <div className="container mx-auto p-4">
          <section className="mt-12">
            <div className="px-2 py-8">
              <div className="container mx-auto">
                <h1 className="font-bold text-4xl mb-8 text-pink-600">
                  Impressum
                </h1>
                <div class="contact-info space-y-2 text-lg">
                  <p>
                    <strong>Katharina Bayer</strong>
                  </p>
                  <p>
                    <strong>Friseur Katharinas</strong>
                  </p>
                  <p>
                    Benninghoferstr 151
                    <br />
                    44269 Dortmund
                    <br />
                    Deutschland
                  </p>
                  <p>Steuernr: 31550134069</p>

                  <p>
                    Tel:{" "}
                    <a
                      href="tel:+49231462411"
                      className="underline text-pink-600"
                    >
                      0231/462411
                    </a>
                  </p>
                  <p>
                    Email:{" "}
                    <a
                      href="mailto:info@friseur-katharinas.de"
                      className="underline text-pink-600"
                    >
                      info@friseur-katharinas.de
                    </a>
                  </p>

                  <p>
                    Plattform der EU-Kommission zur Online-Streitbeilegung:{" "}
                    <a
                      href="https://eu.europa.eu/odr"
                      className="underline text-pink-600"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://eu.europa.eu/odr
                    </a>
                  </p>

                  <p>
                    Wir sind zur Teilnahme an einem Streitbeilegungsverfahren
                    von einer Verbraucherschlichtungsstelle weder verpflichtet
                    noch bereit.
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>

        <div className="container mx-auto p-4">
          <h1 className="text-2xl font-bold mb-6">
            Allgemeine Geschäftsbedingungen für Friseur Katharina in Dortmund
            Benninghofen
          </h1>

          <Section title="1. Terminvereinbarung">
            Kunden müssen Termine im Voraus vereinbaren. Termine können
            telefonisch oder persönlich, online oder vor Ort vereinbart werden.
          </Section>

          <Section title="2. Stornierungs- und Verspätungsrichtlinien">
            Kunden werden gebeten, Termine mindestens 24 Stunden im Voraus zu
            stornieren oder zu verschieben. Bei Nichterscheinen oder verspäteter
            Absage behalten wir uns das Recht vor, eine Anuahlung in Höhe von 50
            € zu erheben.
          </Section>

          <Section title="3. Behandlungen und Preise">
            Die angebotenen Dienstleistungen und Preise werden deutlich
            kommuniziert. Zusätzliche Dienstleistungen können gegen Aufpreis
            angeboten werden.
          </Section>

          <Section title="4. Zahlungsbedingungen">
            <p>
              Kunden haben die Möglichkeit, ihre Dienstleistungen ausschließlich
              bar zu bezahlen.
            </p>
            <p>
              Die Zahlung erfolgt unmittelbar nach Abschluss der Dienstleistung.
              Die entsprechende Rechnung wird dem Kunden ausgehändigt oder auf
              Wunsch per E-Mail zugesandt.
            </p>
            <p>
              Alle Preise verstehen sich inklusive der gesetzlichen
              Mehrwertsteuer. Es werden keine zusätzlichen Gebühren oder Steuern
              erhoben, es sei denn, dies wird ausdrücklich angegeben.
            </p>
            {/* Add other sub-points similarly */}
          </Section>

          <Section title="5. Hygiene und Sicherheit">
            Wir halten strenge Hygiene- und Sicherheitsstandards ein, um die
            Gesundheit und das Wohlbefinden unserer Kunden zu gewährleisten.
          </Section>

          <Section title="6. Kundenzufriedenheit">
            Wir streben danach, unseren Kunden den bestmöglichen Service zu
            bieten. Sollten Kunden mit der erbrachten Dienstleistung nicht
            zufrieden sein, bitten wir sie, dies umgehend mitzuteilen, damit wir
            eine zufriedenstellende Lösung finden können.
          </Section>

          <Section title="7. Haftungsausschluss">
            Der Friseursalon haftet nicht für Schäden an Kleidung, Schmuck oder
            persönlichen Gegenständen des Kunden während des Besuchs im Salon.
          </Section>

          <Section title="8. Änderungen der Geschäftsbedingungen">
            Der Friseursalon behält sich das Recht vor, diese
            Geschäftsbedingungen jederzeit und ohne Vorankündigung zu ändern.
            Die aktualisierten Bedingungen werden auf der Website des Salons
            veröffentlicht.
          </Section>

          <Section title="10. Bildbeispiele">
            Wenn Friseure Katharinas Bildbeispiele im Salon oder auf der
            Homepage veröffentlicht, sind dies lediglich Beispiele. Selbst wenn
            die Frisur oder Farbe am Kunden nachgearbeitet wird, kann es zu
            Abweichungen bezüglich des Designs, der Form und der Wirkung des
            Designs am Kunden kommen. Das Resultat kann von der gezeigten
            Bildvorlage abweichen. Das Urheberrecht für das Design verbleibt bei
            Friseure Katharinas. Sollte der Kunde vom erstellten Design
            Fotografien anfertigen, die für kommerzielle Zwecke verwendet werden
            (Internet, Publikationen usw.), bedarf dies einer schriftlichen
            Einverständniserklärung durch Friseure Katharinas. Bei Unterlassen
            kann Friseure Katharinas angemessene Ansprüche geltend machen.
          </Section>

          <div className="my-8">
            <h2 className="text-xl font-bold mb-4">Reklamationsbedingungen:</h2>

            <div className="mb-4">
              <h3 className="font-semibold">1. Reklamationsfrist:</h3>
              <p>
                Kunden haben das Recht, innerhalb von 7 Tagen nach Erhalt der
                Dienstleistung etwaige Mängel oder Unzufriedenheiten bezüglich
                ihrer Frisur zu reklamieren.
              </p>
            </div>

            <div className="mb-4">
              <h3 className="font-semibold">Reklamationsverfahren:</h3>
              <p>
                Um eine Reklamation geltend zu machen, muss der Kunde persönlich
                im Salon erscheinen und die reklamierte Frisur vorzeigen. Eine
                telefonische oder schriftliche Reklamation ist nicht
                ausreichend.
              </p>
            </div>

            <div className="mb-4">
              <h3 className="font-semibold">3. Nachbesserung:</h3>
              <p>
                Im Falle einer berechtigten Reklamation bemühen wir uns, das
                Problem so schnell wie möglich zu beheben. Der Kunde hat
                Anspruch auf eine kostenlose Nachbesserung der beanstandeten
                Dienstleistung.
              </p>
            </div>

            <div className="mb-4">
              <h3 className="font-semibold">
                4. Umtausch oder Rückerstattung:
              </h3>
              <p>
                Sollte eine Nachbesserung nicht möglich sein oder vom Kunden
                nicht gewünscht werden, kann nach eigenem Ermessen des
                Friseursalons eine teilweise Rückerstattung oder ein Umtausch
                angeboten werden.
              </p>
            </div>

            <div className="mb-4">
              <h3 className="font-semibold">
                5. Ausschluss von Reklamationen:
              </h3>
              <p>
                Reklamationen werden nicht akzeptiert, wenn der Kunde nach dem
                Verlassen des Salons Änderungen an der Frisur vorgenommen hat
                oder wenn die Reklamation auf unsachgemäße Pflege oder
                Handhabung zurückzuführen ist.
              </p>
            </div>

            <div className="mb-4">
              <h3 className="font-semibold">6. Haftungsausschluss:</h3>
              <p>
                Der Friseursalon haftet nicht für Schäden, die durch
                unsachgemäße Verwendung von Produkten oder andere externe
                Einflüsse entstehen.
              </p>
            </div>

            <div className="mb-4">
              <h3 className="font-semibold">7. Dokumentation:</h3>
              <p>
                Zur Bearbeitung der Reklamation kann der Friseursalon den Kunden
                bitten, Fotos der beanstandeten Frisur vorzulegen.
              </p>
            </div>

            <div className="mb-4">
              <h3 className="font-semibold">8. Schlichtung:</h3>
              <p>
                Im Falle von Unstimmigkeiten oder Konflikten bemühen wir uns,
                eine faire und einvernehmliche Lösung zu finden. Bei Bedarf kann
                ein neutraler Vermittler hinzugezogen werden.
              </p>
            </div>

            <p>
              Wir bitten unsere Kunden, etwaige Unzufriedenheiten offen
              anzusprechen, damit wir die Möglichkeit haben, das Problem zu
              beheben und unseren Service kontinuierlich zu verbessern.
            </p>

            <p>
              Mit der Inanspruchnahme unserer Dienstleistungen erklären Sie sich
              mit diesen Allgemeinen Geschäftsbedingungen einverstanden.
            </p>
          </div>
        </div>

        <TakeCare />
      </div>
    </>
  );
}

export default Imprint;
