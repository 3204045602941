import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const reviews = [
  {
    id: 1,
    name: "Nadine Schatton",
    rating: 5,
    reviewText:
      "Der beste Friseur seit Jahren... Einfach ein tolles Team... Ich fühle mich seit Jahren wie zu Hause...🥰 es sollte mehr als 5 Sterne geben!!!! Danke Kati und Peggy. Ich lasse dort nur meine Wimpern und Haare machen 😘",
    imageSrc:
      "https://lh3.googleusercontent.com/a/ACg8ocLD5RJzOCXFWwdx7fukiQF4X1wQRj3ycr_ceug_1Uyo=w60-h60-p-rp-mo-br100",
  },
  {
    id: 2,
    name: "Yvonne Brahm",
    rating: 5,
    reviewText:
      "Für meine Familie und mich das beste Friseurteam aller Zeiten, geht auf unsere Wünsche ein, Preis-Leistung stimmt. Sehr, sehr nettes und kompetentes Team.",
    imageSrc:
      "https://lh3.googleusercontent.com/a/ACg8ocL49Kkn6dEAVwBwoHYfyUf-offErvYk417wmNol_AlQ=w60-h60-p-rp-mo-br100",
  },
  {
    id: 3,
    name: "Carolin Nowak",
    rating: 5,
    reviewText:
      "Ein Besuch ist immer eine kleine Auszeit vom Alltag! Es ist sehr warm und familiär dort",
    imageSrc:
      "https://lh3.googleusercontent.com/a/ACg8ocIcSVBCBBvpiEOXcRE_HPO3DYWBlPn-rbQnW5GYRFY4=w60-h60-p-rp-mo-br100",
  },
  {
    id: 4,
    name: "Simone Prinz",
    rating: 5,
    reviewText:
      "Ihr seid die besten Friseure, die ich kenne, nur zu euch 👍🏻 bleibt wie ihr seid.",
    imageSrc:
      "https://lh3.googleusercontent.com/a/ACg8ocImnRfLfjFFVVXed45ztfvROO8-1Qf7KFlWO2QeUkDhGw=w36-h36-p-rp-mo-br100",
  },
  {
    id: 5,
    name: "Pina Melone",
    rating: 5,
    reviewText:
      "Ich bin seit Jahren Kundin und seit dem 1. Tag super zufrieden. Kathi und Peggy sind liebenswerte Menschen und man fühlt sich immer willkommen. Als Kunde wird man rundum verwöhnt (sei es mit Getränken oder Snacks).",
    imageSrc:
      "https://lh3.googleusercontent.com/a/ACg8ocKB5HKyii2LGklJb_AkhylF5WfE-XzE7f3aThEUZtI7=w60-h60-p-rp-mo-br100",
  },
  {
    id: 6,
    name: "John Long",
    rating: 5,
    reviewText:
      "Super nette Mädchen, ein toller Spielbereich für die kleinen Kunden und immer nette Gespräche.Preise sind in Ordnung und die Haare sehen nach dem Besuch wieder großartig aus.",
    imageSrc:
      "https://lh3.googleusercontent.com/a/ACg8ocJN8eFMkWSyNpOcci_oOlcIxfvJ0Z6IntzLzvKQNKDB=w36-h36-p-rp-mo-br100",
  },
  {
    id: 7,
    name: "Kerstin Washof",
    rating: 5,
    reviewText:
      "Sehr freundliches Personal und wirklich gutes Essen zu vernünftigen Preisen.",
    imageSrc:
      "https://lh3.googleusercontent.com/a-/ALV-UjWyDKOCkpi0F1pGLXsWeIwXeAcqndW6tqfX0pZNi8VIJa4=w36-h36-p-rp-mo-br100",
  },
  {
    id: 8,
    name: "Michelle Krause",
    rating: 5,
    reviewText:
      "Einfach großartig und liebevolle Mitarbeiter, die einen dort willkommen heißen 🙂 ... Kathi & Peggy haben es geschafft, mich von jahrelang (dunkel) rot-gefärbten DM Produkten auf Grau beim zweiten Termin zu bekommen, weil ich meine Wurzeln nicht mehr sehen konnte, da ich seit ich 18 bin grau/weiße Haare geerbt hatte und kann nun einen schönen, nicht so auffälligen Ansatz herauswachsen lassen :) Friseur Katharinas ist definitiv zu empfehlen 👍🏼.",
    imageSrc:
      "https://lh3.googleusercontent.com/a-/ALV-UjVz42g7lFNuG1EVMM5CuGrKPiunjHhTx956m1w8thQcj5g=w60-h60-p-rp-mo-br100",
  },
  {
    id: 9,
    name: "Mandy B.",
    rating: 5,
    reviewText:
      "Toller Friseur! Wir kommen regelmäßig hierher und sind immer absolut glücklich mit den Ergebnissen. ❤️😊",
    imageSrc:
      "https://lh3.googleusercontent.com/a-/ALV-UjU2wDhVr7ZKTd0l8Qsh4zDJ9ZhwIakgfF8maOGfAbVtsLc=w36-h36-p-rp-mo-ba2-br100",
  },
  {
    id: 10,
    name: "Wuji Zhang",
    rating: 5,
    reviewText: "Guter Friseursalon. Sie sind freundlich und machen gute Arbeit.",
    imageSrc: "https://lh3.googleusercontent.com/a-/ALV-UjUkOIx10XYcPXwNbSb-1rm8WH1AdD2BqZckJG9nB7tHJ2E=w60-h60-p-rp-mo-ba3-br100",
  },
];


const ReviewCard = ({ review }) => {
  const { name, rating, reviewText, imageSrc } = review;

  // Function to render stars based on the rating
  const renderStars = (rating) => {
    let stars = [];
    for (let i = 0; i < rating; i++) {
      stars.push(
        <span key={i} className="text-lg text-pink-700">
          ★
        </span>
      );
    }
    return stars;
  };

  return (
    <div className="p-4 bg-lightpink shadow-lg rounded-lg min-h-[260px]">
      <div className="flex flex-col items-center">
        <img
          src={imageSrc}
          alt={name}
          className="rounded-full w-20 h-20 object-cover mb-3"
        />
        <div className="flex items-center mb-1">
          <h5 className="text-white text-lg font-bold">{name}</h5>
        </div>
        <div className="flex items-center justify-center">
          <div className="ml-2">{renderStars(rating)}</div>
        </div>
        <p className="text-white text-sm text-center mt-2 line-clamp-3">
          {reviewText}
        </p>
      </div>
    </div>
  );
};

export const ReviewSlider = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="container mx-auto">
      <Slider {...settings}>
        {reviews.map((review) => (
          <div key={review.id} className="px-2">
            <ReviewCard review={review} />
          </div>
        ))}
      </Slider>
    </div>
  );
};
