import React, { useState, useEffect } from "react";
import qrcode from "../../../Assets/qrcode.jpg";

const Counter = ({ start, end, label }) => {
  const [count, setCount] = useState(start);

  useEffect(() => {
    if (count < end) {
      const timer = setTimeout(() => setCount(count + 1), 0.8);
      return () => clearTimeout(timer);
    }
  }, [count, end]);

  return (
    <div className="text-center">
      <div className="text-5xl font-semibold text-pink-600">{count}</div>
      <div className="text-lg text-pink-600">{label}</div>
    </div>
  );
};

const CounterSteps = () => {
  return (
    <div className="bg-pink-200">
      <div className="container mx-auto">
        <div className="flex items-center flex-col gap-8 sm:flex-row sm:gap-0 justify-center sm:justify-around bg-pink-200 px-4 py-16">
          <p className="max-w-2xl text-xl text-pink-600 font-extrabold uppercase">
            Jeden Freitag bieten wir euch kostenlos einen Cocktail von
            City-Cocktails zu eurem Termin an.
          </p>
          <img src={qrcode} className="w-32 h-32"></img>
        </div>
      </div>
    </div>
  );
};

export default CounterSteps;
