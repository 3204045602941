import "./App.css";
import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import Home from "./Components/Home/Home";

import Navbar from "./Components/Navbar/Navbar";
import Contact from "./Components/Contact/Contact";
import Footer from "./Components/Footer/Footer";
import Service from "./Components/Services/Service";

import About from "./Components/About/About";

import { BlogPage } from "./Components/BlogPages/BlogPages";

import { ScrollToTop } from "./ScrollToTop";
import Imprint from "./Components/Imprint/Imprint";

const Events = React.lazy(() => import("./Components/Events/Events"));
const Gallery = React.lazy(() => import("./Components/Gallery/Gallery"));

function App() {
  return (
    <>
      <Navbar />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<Service />} />

        <Route path="/about-us" element={<About />} />

        <Route
          path="/gallery"
          element={
            <React.Suspense fallback={<>...</>}>
              <Gallery />
            </React.Suspense>
          }
        />
        <Route
          path="/events"
          element={
            <React.Suspense fallback={<>...</>}>
              <Events />
            </React.Suspense>
          }
        />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/blog/:blogUrl" element={<BlogPage />} />

        <Route path="/imprint" element={<Imprint/>} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
