import banner from "../../Assets/banner.jpg";
import React, { useEffect, useState, useRef } from "react";

import parlourImg from "../../Assets/parlour.jpg";
import banner1 from "../../Assets/banner1.png";
import "./Home.css";

import hairdye from "../../Assets/beauty-treatment.png";
import haircut from "../../Assets/haircut.png";
import makeup from "../../Assets/makeover.png";
import hairtrim from "../../Assets/beard-trimming.png";

import product1 from "../../Assets/products/product1.jpg";
import product2 from "../../Assets/products/product2.jpg";
import product3 from "../../Assets/products/product3.jpg";
import product4 from "../../Assets/products/product4.jpg";
import product5 from "../../Assets/products/product5.jpg";
import product6 from "../../Assets/products/product6.jpg";
import product7 from "../../Assets/products/product7.jpg";
import product8 from "../../Assets/products/product14.jpg";
import product9 from "../../Assets/products/product9.jpg";
import product10 from "../../Assets/products/product10.jpg";
import product11 from "../../Assets/products/product11.jpg";
import product13 from "../../Assets/products/product13.jpg";

import { ServiceSection } from "../Sections/ServiceCard/ServiceCard";
import { FAQs } from "../Sections/FAQ/FAQ";
import { Article } from "../Sections/Articles/Articles";
import { ReviewSlider } from "../Sections/Review/Review";

import { TeamBanner } from "../Sections/TeamBanner/TeamBanner";
import { TakeCare } from "../Sections/TakeCare/TakeCare";
import { SearchBanner } from "../Sections/SearchBanner/SearchBanner";
import { HelmetSEO } from "../../HelmetSEO";
import { BookAppointment, ReadMore } from "../Sections/Button/Button";
import CounterSteps from "../Sections/CounterSteps/CounterSteps";
import TimingsSection from "../Sections/TimingsSection/TimingsSection";

import video from "../../Assets/Friseur.mp4";

function Home() {
  const [lightbox, setLightbox] = useState({ open: false, imgSrc: "" });
  const openLightbox = (imgSrc) => {
    setLightbox({ open: true, imgSrc });
  };

  const closeLightbox = () => {
    setLightbox({ open: false, imgSrc: "" });
  };
  return (
    <>
      <HelmetSEO
        title="Friseur - Bester Entspannungs- und Friseursalon"
        description="Entdecken Sie Friseur Katharinas in Dortmund für exzellente Haarschnitte und Farben. Professioneller Service für Männer, Frauen, Kinder in modernem Ambiente. Beste Produkte, neueste Trends."
        url="https://www.friseur-katharinas.de/"
      />

      <div class="bg-white">
        <div className="min-w-full bg-center bg-cover bg-lightpink h-full object-cover h-auto">
          <section className="mx-auto container py-8 md:py-16 px-2">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div className="px-2 py-4 clipPath1 order-2 md:order-1">
                <h1 className="text-4xl sm:text-6xl font-bold mb-4">
                  Bester Entspannungs- und Friseursalon
                </h1>
                <p className="text-md text-white">
                  Friseur Katharinas aus Dortmund Benninghofen ist ein
                  renommierter Friseursalon, der für seine erstklassigen
                  Dienstleistungen und sein engagiertes Team bekannt ist. Der
                  Salon befindet sich in einer zentralen Lage in Dortmund
                  Benninghofen und bietet eine breite Palette von
                  Friseurdienstleistungen für Damen, Herren und Kinder an.
                  <br></br> <br></br>
                  Das Team von Friseur Katharinas besteht aus hochqualifizierten
                  und erfahrenen Friseurinnen , die stets darauf bedacht sind,
                  die individuellen Bedürfnisse und Wünsche ihrer Kunden zu
                  erfüllen. Sie verfügen über umfangreiches Fachwissen in den
                  Bereichen Haarschnitt, Styling, Färben, Strähnen und
                  Extensions.
                  <br></br> <br></br>
                  Was den Salon besonders auszeichnet, ist die persönliche und
                  herzliche Atmosphäre, die von Katharina selbst geschaffen
                  wurde. Sie legt großen Wert darauf, dass sich ihre Kunden wohl
                  und gut aufgehoben fühlen. Die Einrichtung des Salons ist
                  modern und einladend gestaltet, was zu einem angenehmen
                  Aufenthalt beiträgt.
                  <br></br> <br></br>
                  Friseur Katharinas ist auch für seine Verwendung hochwertiger
                  Produkte und innovativer Techniken bekannt. Das Team arbeitet
                  mit führenden Marken der Friseurbranche zusammen, um
                  sicherzustellen, dass die Kunden stets die bestmögliche
                  Qualität erhalten.
                  <br></br> <br></br>
                  Der Salon bietet zudem regelmäßig Schulungen und
                  Weiterbildungen für sein Team an, um sicherzustellen, dass sie
                  stets auf dem neuesten Stand der Trends und Techniken sind.
                  <br></br> <br></br>
                  Insgesamt ist Friseur Katharinas in Dortmund Benninghofen eine
                  erstklassige Adresse für alle, die nach professionellen
                  Friseurdienstleistungen in einer angenehmen und freundlichen
                  Umgebung suchen.
                </p>
                <ReadMore />
              </div>
              <div className="px-2 py-4 order-1 md:order-2">
                <section className="mx-auto max-w-lg">
                  <div className="p-4 rounded-md border border-pink-600">
                    <video
                      className="h-full w-full rounded-lg"
                      controls
                      autoPlay
                    >
                      <source src={video} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </section>
              </div>
            </div>
          </section>
        </div>

        <div className="container mx-auto px-4 py-8">
          <div className="grid md:grid-cols-2 gap-8 items-center">
            <div class="aspect-w-16 aspect-h-9">
              <img
                src={parlourImg}
                alt=""
                className="rounded-sm w-full h-auto max-h-[500px]"
              />
            </div>
            <div className="space-y-2">
              <a
                href="https://www.instagram.com/friseur_katharinas/"
                className="text-black hover:text-pink-600 underline"
              >
                Insta: Friseur_katharinas
              </a>
              <h1 className="font-bold text-4xl mt-2 mb-2 text-pink-600">
                Es ist Zeit zum Entspannen!
              </h1>
              <p>
                Buche jetzt deinen persönlichen Termin bei Friseur Katharinas
                und gönn dir eine wohlverdiente Auszeit vom Alltag. Bei uns
                kannst du dich ganz auf dich konzentrieren, während du bei einer
                Tasse aromatischem Kaffee entspannst und dich von unserem
                erfahrenen Team verwöhnen lässt. Genieße die professionelle
                Beratung und das individuelle Styling, das genau auf deine
                Wünsche und Bedürfnisse zugeschnitten ist. Entdecke ein neues
                Gefühl von Frische und Selbstbewusstsein, während du dich bei
                uns verwöhnen lässt und erfrischt fühlst.
              </p>
              <div className="flex flex-wrap gap-4 flex-row justify-between sm:justify-around items-center">
                <div className="text-center">
                  <img
                    src={hairdye}
                    alt="Massage"
                    className="mx-auto mt-2 mb-2 w-16 h-16"
                  />
                  <p className="mt-2 font-semibold">Haarfärben</p>
                </div>
                <div className="text-center">
                  <img
                    src={haircut}
                    alt="Therapies"
                    className="mx-auto mt-2 mb-2 w-16 h-16"
                  />
                  <p className="mt-2 font-semibold">Haarschnitt</p>
                </div>
                <div className="text-center">
                  <img
                    src={makeup}
                    alt="Relaxation"
                    className="mx-auto mt-2 mb-2 w-16 h-16"
                  />
                  <p className="mt-2 font-semibold">Makeup</p>
                </div>
                <div className="text-center">
                  <img
                    src={hairtrim}
                    alt="Facial"
                    className="mx-auto mt-2 mb-2 w-16 h-16"
                  />
                  <p className="mt-2 font-semibold">Trimmen</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-gray-100">
          <section className="container mx-auto py-12 px-4">
            <div className="p-2 lg:p-4">
              <div className="container mx-auto">
                <p className="text-center uppercase">Dienstleistungen</p>
                <h1 className="font-bold text-4xl mt-2 mb-2 text-center text-pink-600">
                  Das Beste für Dich
                </h1>

                <ServiceSection />
              </div>
            </div>
          </section>
        </div>

        <TeamBanner />

        <div className="container mx-auto">
          <div className="py-16 px-4 grid md:grid-cols-2 gap-8 items-center">
            <div className="space-y-8">
              <h1 className="text-4xl font-extrabold text-pink-600">
                Unsere Position
              </h1>
              <p>
                Unser Salon, Friseur Katharinas, liegt eingebettet im
                wunderschönen Dortmund Benninghofen, genau in der idyllischen
                Benninghofer Straße 151. Wir freuen uns darauf, dich in unserem
                gemütlichen Salon begrüßen zu dürfen, wo wir mit Leidenschaft
                und Können darauf warten, dich zu verwöhnen und zu verschönern.
                Bis bald bei uns!
              </p>

              <div className="mt-8 flex">
                <BookAppointment />
              </div>
            </div>
            <div class="aspect-w-16 aspect-h-9">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2485.0023045878634!2d7.509011499999999!3d51.476472!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b917d72643b79d%3A0xf3e904489c4771b1!2sFriseur%20Katharinas!5e0!3m2!1sen!2s!4v1709153929652!5m2!1sen!2s"
                width="100%"
                style={{ border: "0" }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
                className="h-[240px] md:h-[450px]"
              ></iframe>
            </div>
          </div>
        </div>

        <CounterSteps />

        <div className="container mx-auto px-4 py-16">
          <p className="text-center">PRODUKTE</p>
          <h1 className="font-bold text-4xl mt-2 mb-2 text-center text-pink-600">
            Qualität Trifft auf Perfekten Stil
          </h1>

          <div class="py-4 grid grid-cols-2 gap-4 md:grid-cols-4">
            <div class="grid gap-4">
              <div>
                <img
                  class="cursor-pointer h-auto max-w-full rounded-lg object-cover object-center"
                  src={product1}
                  alt="gallery-photo"
                  onClick={() => openLightbox(product1)}
                />
              </div>
              <div>
                <img
                  class="cursor-pointer h-auto max-w-full rounded-lg object-cover object-center"
                  src={product4}
                  alt="gallery-photo"
                  onClick={() => openLightbox(product4)}
                />
              </div>
              <div>
                <img
                  class="cursor-pointer h-auto max-w-full rounded-lg object-cover object-center"
                  src={product7}
                  alt="gallery-photo"
                  onClick={() => openLightbox(product7)}
                />
              </div>
            </div>
            <div class="grid gap-4">
              <div>
                <img
                  class="cursor-pointer h-auto max-w-full rounded-lg object-cover object-center"
                  src={product2}
                  alt="gallery-photo"
                  onClick={() => openLightbox(product2)}
                />
              </div>
              <div>
                <img
                  class="cursor-pointer h-auto max-w-full rounded-lg object-cover object-center"
                  src={product5}
                  alt="gallery-photo"
                  onClick={() => openLightbox(product5)}
                />
              </div>
              <div>
                <img
                  class="cursor-pointer h-auto max-w-full rounded-lg object-cover object-center "
                  src={product13}
                  alt="gallery-photo"
                  onClick={() => openLightbox(product13)}
                />
              </div>
            </div>
            <div class="grid gap-4">
              <div>
                <img
                  class="cursor-pointer h-auto max-w-full rounded-lg object-cover object-center"
                  src={product8}
                  alt="gallery-photo"
                  onClick={() => openLightbox(product8)}
                />
              </div>
              <div>
                <img
                  class="cursor-pointer h-auto max-w-full rounded-lg object-cover object-center "
                  src={product6}
                  alt="gallery-photo"
                  onClick={() => openLightbox(product6)}
                />
              </div>
              <div>
                <img
                  class="cursor-pointer h-auto max-w-full rounded-lg object-cover object-center"
                  src={product3}
                  alt="gallery-photo"
                  onClick={() => openLightbox(product3)}
                />
              </div>
            </div>
            <div class="grid gap-4">
              <div>
                <img
                  class="cursor-pointer h-auto max-w-full rounded-lg object-cover object-center"
                  src={product9}
                  alt="gallery-photo"
                  onClick={() => openLightbox(product9)}
                />
              </div>
              <div>
                <img
                  class="cursor-pointer h-auto max-w-full rounded-lg object-cover object-center"
                  src={product10}
                  alt="gallery-photo"
                  onClick={() => openLightbox(product10)}
                />
              </div>
              <div>
                <img
                  class="cursor-pointer h-auto max-w-full rounded-lg object-cover object-center"
                  src={product11}
                  alt="gallery-photo"
                  onClick={() => openLightbox(product11)}
                />
              </div>
            </div>
          </div>
          {lightbox.open && (
            <div
              className="fixed inset-0 bg-black bg-opacity-80 flex justify-center items-center p-4"
              style={{ zIndex: "1000000000000000000000000000000000" }}
              onClick={closeLightbox}
            >
              <div className="rounded-md bg-white p-2">
                <img
                  className="h-full max-h-[600px] max-w-full rounded-lg object-contain"
                  src={lightbox.imgSrc}
                  alt="Enlarged gallery photo"
                  onClick={(e) => e.stopPropagation()} // Prevent click inside the lightbox from closing it
                />
              </div>
            </div>
          )}
        </div>

        <SearchBanner />

        <TimingsSection />

        <FAQs />

        <div className="relative bg-white">
          <div
            className="clipPath absolute inset-0"
            style={{ clipPath: "polygon(100% 0, 30% 0, 100% 120%)" }}
          ></div>
          <div className="border-b-8 border-t-8 border-lightpink ">
            <section className=" py-12 container mx-auto p-4">
              <div className="relative z-10 ">
                <div className="max-w-xl space-y-4 mb-12">
                  <h2 className="text-2xl md:text-4xl text-pink-600 font-extrabold">
                    Die Extrameile gehen
                  </h2>
                  <p className="text-md md:text-lg font-semibold">
                    Erkunden Sie Friseur Katharinas in Dortmund Benninghofen,
                    einen erstklassigen Friseursalon, bekannt für
                    personalisierte Styling-Dienstleistungen für Männer, Frauen
                    und Kinder. Genießen Sie fachmännische Haarschnitte,
                    Färbungen und eine warme Atmosphäre mit moderner
                    Einrichtung. Wir verwenden hochwertige Produkte und bleiben
                    stets auf dem neuesten Stand der Trends für Ihren besten
                    Look.
                  </p>
                </div>
                <ReviewSlider />
              </div>
            </section>
          </div>
        </div>

        <TakeCare />
      </div>
    </>
  );
}

export default Home;
