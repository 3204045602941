import "./SearchBanner.css";

export const SearchBanner = () => (
  <div className="min-w-full h-auto banner2">
    <section className="mx-auto container py-8 sm:py-16 px-4 relative z-10">
      <div className="max-w-3xl space-y-4">
        <h1 className="text-2xl lg:text-4xl text-pink-600 font-extrabold">
          Bist du auf der Suche nach dem besten Familien friseur in Dortmund? Du
          hast Ihn gefunden
        </h1>
        <p className="text-base lg:text-lg font-semibold">
        Herzlichen Glückwunsch! Du hast den besten Familienfriseur in Dortmund gefunden. Bei uns steht nicht nur die Perfektion in Sachen Haarschnitt im Vordergrund, sondern auch das Wohlbefinden der ganzen Familie. Wir haben sogar eine spezielle Spielecke, damit sich deine Kinder während ihres Friseurbesuchs vergnügen können. Bei uns herrscht keine Hektik, sondern eine entspannte Atmosphäre, in der nicht nur die Eltern, sondern auch die Kinder ihren Besuch bei uns genießen können.
        </p>
      </div>
    </section>
  </div>
);
