import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { HelmetSEO } from "../../HelmetSEO";
function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const [errors, setErrors] = useState({
    name: false,
    email: false,
    phone: false,
    message: false,
  });

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    let formIsValid = true;

    const requiredFields = {
      name,
      email,
      phone,
      message,
    };

    const newErrors = { ...errors };

    // Remove non-digit characters and country code for phone validation
    // const justDigitsPhone = phone.replace(/\D/g, '').replace(/^49/, '');

    // Check for empty fields and phone length
    Object.keys(requiredFields).forEach((field) => {
      if (!requiredFields[field]) {
        formIsValid = false;
        newErrors[field] = true;
      } else if (field === "phone" && phone.length !== 14) {
        // Specifically for phone, check if the length is exactly 14 digits (space also is counted)
        formIsValid = false;
        newErrors[field] = true;
      } else {
        newErrors[field] = false;
      }
    });

    setErrors(newErrors);

    if (formIsValid) {
      try {
        // Perform form submission tasks here
        console.log("Form submitted successfully with", {
          name,
          email,
          phone,
          message,
        });

        // Reset form fields
        setName("");
        setEmail("");
        setPhone("");
        setMessage("");

        // Indicate success to the user
      } catch (error) {
        // Handle submission error
        console.error("Submission error", error);
      }
    }
  };

  const handlePhoneChange = (e) => {
    let input = e.target.value.replace(/\D/g, "");

    if (!input.startsWith("49")) {
      if (input.startsWith("0")) {
        input = "49" + input.substring(1);
      } else {
        input = "49" + input;
      }
    }

    let formattedInput = "+49 ";

    if (input.length > 2) {
      formattedInput += input.substring(2, 5);
    }
    if (input.length > 5) {
      formattedInput += ` ${input.substring(5, 11)}`;
    }

    setPhone(formattedInput);

    setErrors({
      ...errors,
      phone: input.length < 11,
    });
  };

  return (
    <>
      <HelmetSEO
        title="Friseur - Kontaktiere uns"
        description="Kontakt Friseur Katharinas: Tel. +49 231 462411, E-Mail: katharina150689@hotmail.com. Abonnieren für exklusive Angebote."
        url="https://www.friseur-katharinas.de/contact-us"
      />
      <div className="bg-white">
        <ToastContainer></ToastContainer>
        <div className="min-h-screen container mx-auto px-2 py-2">
          <div className="container mx-auto">
            <div className="py-8 md:py-12 px-2 grid md:grid-cols-2 gap-8 items-center">
              {/* <div className=" py-4 md:p-4">
              <h2 className="text-2xl font-bold  text-center mb-4">
                Nehmen Sie noch heute Kontakt mit uns auf!
              </h2>
              <form onSubmit={handleFormSubmit}>
                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="name"
                  >
                    Ihr Name*
                    {errors.name && (
                      <small className="text-red-500">* Required</small>
                    )}
                  </label>
                  <input
                    id="name"
                    name="name"
                    type="text"
                    placeholder="JOHN DOE"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                      setErrors({ ...errors, name: false });
                    }}
                    className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700  focus:outline-none focus:shadow-outline ${
                      errors.name ? "border-red-500" : "border-gray-300"
                    } rounded w-full py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline`}
                  />
                </div>

                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="phone"
                  >
                    Deine Telefonnummer*{" "}
                    {errors.phone && (
                      <small className="text-red-500">* Required</small>
                    )}
                  </label>
                  <input
                    id="phone"
                    type="phone"
                    name="phone"
                    placeholder="(123) 456-7890"
                    value={phone}
                    onChange={handlePhoneChange}
                    className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700  focus:outline-none focus:shadow-outline ${
                      errors.phone ? "border-red-500" : "border-gray-300"
                    } rounded w-full py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline`}
                  />
                </div>

                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="email"
                  >
                    Email{" "}
                    {errors.email && (
                      <small className="text-red-500">* Required</small>
                    )}
                  </label>
                  <input
                    id="email"
                    type="email"
                    name="email"
                    placeholder="JOHN.DOE@GMAIL.COM"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setErrors({ ...errors, email: false });
                    }}
                    className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700  focus:outline-none focus:shadow-outline ${
                      errors.email ? "border-red-500" : "border-gray-300"
                    }`}
                  />
                </div>

                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="email"
                  >
                    Nachricht{" "}
                    {errors.message && (
                      <small className="text-red-500">* Required</small>
                    )}
                  </label>
                  <textarea
                    id="message"
                    type="text"
                    name="message"
                    placeholder="NACHRICHT"
                    value={message}
                    onChange={(e) => {
                      setMessage(e.target.value);
                      setErrors({ ...errors, message: false });
                    }}
                    className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700  focus:outline-none focus:shadow-outline ${
                      errors.message ? "border-red-500" : "border-gray-300"
                    }`}
                  ></textarea>
                </div>

                <div className="mt-8 flex">
                  <button
                    className="h-12 w-full relative bg-pink-500 text-white font-bold text-xl hover:bg-pink-600 flex items-center justify-center group"
                    type="submit"
                  >
                    <span className="w-full flex-auto text-center px-4">
                      Einreichen
                    </span>
                    <span className="h-full right-0 inset-y-0 bg-pink-600 flex items-center justify-center w-16 group-hover:bg-pink-700">
                      <i className="fas fa-chevron-right text-white"></i>
                    </span>
                  </button>
                </div>
              </form>
            </div> */}
              <div className="text-lg font-bold space-y-4 py-4 md:p-4">
                <p>Telefonnummer: +49 231 462411</p>
                <p>Mail-Adresse: katharina150689@hotmail.com</p>
                <p>
                  Abonniere den Kanal Friseur Katharinas ,,INSIDER" auf
                  WhatsApp:{" "}
                  <a
                    href="https://whatsapp.com/channel/0029VaOqiesCsU9LGNSTec0z"
                    className="text-indigo-600 underline"
                  >
                    Link
                  </a>
                </p>
              </div>
              <div class="aspect-w-16 aspect-h-9">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2485.0023045878634!2d7.509011499999999!3d51.476472!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b917d72643b79d%3A0xf3e904489c4771b1!2sFriseur%20Katharinas!5e0!3m2!1sen!2s!4v1709153929652!5m2!1sen!2s"
                  width="100%"
                  style={{ border: "0" }}
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                  className="h-[240px] md:h-[450px]"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
