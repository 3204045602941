import React, { useEffect, useState, useRef } from "react";

import { ServiceSection } from "../Sections/ServiceCard/ServiceCard";
import { HelmetSEO } from "../../HelmetSEO";
import "./Service.css";
import { FAQs } from "../Sections/FAQ/FAQ";
import { Article } from "../Sections/Articles/Articles";
import { TakeCare } from "../Sections/TakeCare/TakeCare";

function Service() {
  return (
    <>
      <HelmetSEO
        title="Dienstleistungen | Friseur Katharinas"
        description="Friseur Katharinas bietet ein breites Spektrum an Friseur- und Make-up-Dienstleistungen an."
        url="https://www.friseur-katharinas.de/services"
      />

      <div>
        <div class="angle-30 angled-bg">
          <div
            class="no_angle_bg_img"
            style={{ backgroundImage: "url('')" }}
          ></div>
        </div>

        <div className="container mx-auto px-4">
          <section className="mt-12">
            <div className="p-2 lg:p-4">
              <div className="container mx-auto">
                <h1 className="font-bold text-4xl mb-8 text-center text-pink-600">
                  Alles, was wir anbieten
                </h1>
                <ServiceSection />
              </div>
            </div>
          </section>
        </div>

        <FAQs />

        <TakeCare />
      </div>
    </>
  );
}

export default Service;
