import React, { useState } from "react";

import service1 from "../../../Assets/service/service1.png";
import service2 from "../../../Assets/service/service2.png";
import service3 from "../../../Assets/service/service3.png";
import service4 from "../../../Assets/service/service4.png";
import service5 from "../../../Assets/service/service5.png";
import service6 from "../../../Assets/service/service6.png";
import service7 from "../../../Assets/service/service7.png";
import service8 from "../../../Assets/service/service8.png";

const services = [
  {
    title: "Extension",
    image: service1,
    description: `Extensions bei Friseur Katharinas bietet die perfekte Möglichkeit, deinem Haar Volumen, Länge und Fülle zu verleihen. Unsere hochqualifizierten Stylisten sind darauf spezialisiert, individuell angepasste Extensions zu kreieren, die natürlich aussehen und sich auch so anfühlen.

Unsere Extensions werden sorgfältig ausgewählt, um sicherzustellen, dass sie perfekt zu deiner natürlichen Haarfarbe und -struktur passen. Wir verwenden nur hochwertige Materialien, die sanft zum Haar sind und eine lange Haltbarkeit gewährleisten.

Unsere Methoden sind die Tressen Technik und Tape in extensions, für ein Beratungstermin melde dich gerne bei uns damit wir die perfekte Lösung für dich finden .`,
  },
  {
    title: "Färben",
    image: service2,
    description: `Bei Friseur Katharinas bieten wir ein umfangreiches Angebot an Haarfärbetechniken, um sicherzustellen, dass deine Haare genau den gewünschten Look bekommen.

Unsere hochqualifizierten Coloristen sind darauf spezialisiert, individuelle Farbkonzepte zu entwickeln, die zu deinem Hautton, deinem Stil und deinen persönlichen Vorlieben passen. Wir verwenden nur hochwertige Haarfärben und -produkte, die schonend zum Haar sind und ein gleichmäßiges und langanhaltendes Farbergebnis garantieren.
      
Egal ob du eine subtile Farbauffrischung, dezente Strähnchen, eine komplett neue Haarfarbe oder eine kreative Farbveränderung wünschst, unser Team steht dir mit fachkundiger Beratung und Expertise zur Seite. Wir nehmen uns Zeit, um deine individuellen Bedürfnisse zu verstehen und gemeinsam mit dir die perfekte Farblösung zu finden.
      
Darüber hinaus legen wir großen Wert auf den Erhalt der Haargesundheit und beraten dich gerne über die richtige Pflege und Nachbehandlung, damit deine neue Haarfarbe lange schön und strahlend bleibt. Besuche uns bei Friseur Katharinas und lass dich von unseren professionellen Coloristen verwöhnen!`,
  },
  {
    title: "Toupe",
    image: service3,
    description:
      "In den Räumlichkeiten von Friseur Katharinas besteht ebenfalls die Möglichkeit, ein individuell angefertigtes Toupet anzufertigen, das ganz nach den spezifischen Anforderungen, Wünschen und persönlichen Vorlieben des Kunden gestaltet wird. Hierbei wird besonderer Wert darauf gelegt, dass das Toupet nicht nur perfekt passt, sondern auch in Bezug auf Farbe, Textur und Stil den individuellen Vorstellungen des Kunden entspricht. Mit einer sorgfältigen Beratung und professionellen Handwerkskunst wird jedes Toupet bei Friseur Katharinas zu einem maßgeschneiderten und authentischen Haarersatz, der dem Kunden ein natürliches und selbstbewusstes Auftreten verleiht.",
  },
  {
    title: "Kindergeburtstag",
    image: service4,
    description: `Feiern Sie den nächsten Kindergeburtstag Ihres kleinen Schatzes auf besondere Weise bei Friseur Katharinas! In unserer kinderfreundlichen Umgebung bieten wir ein unvergessliches Erlebnis für Ihr Kind und seine Freunde. Unsere professionellen Stylisten verwandeln die Kinder in kleine Prinzessinnen , während sie sich in unserem Salon amüsieren. Mit Spielen, Musik und einer besonderen Atmosphäre wird der Kindergeburtstag zu einem unvergesslichen Ereignis. Kontaktieren Sie uns noch heute, um Ihren Termin zu vereinbaren und Ihrem Kind einen Geburtstag zu bereiten, an den es sich noch lange erinnern wird!`,
  },
  {
    title: "Braut",
    image: service5,
    description: `Friseur Katharinas ist Ihr verlässlicher Partner, um Sie für Ihren großen Tag strahlend schön zu machen. Wir verstehen, dass Ihre Hochzeit einer der wichtigsten Tage in Ihrem Leben ist, und wir sind hier, um sicherzustellen, dass Sie sich wunderschön und selbstbewusst fühlen.

Unsere erfahrenen Stylisten bieten eine Vielzahl von Hochzeitsfrisuren und Make-up-Services an, die individuell auf Ihren Stil und Ihre Wünsche zugeschnitten sind. Egal, ob Sie eine klassische Hochsteckfrisur, romantische Locken oder ein glamouröses Styling bevorzugen - wir werden gemeinsam mit Ihnen das perfekte Aussehen kreieren, das Ihren persönlichen Stil und Ihre Persönlichkeit unterstreicht.
        
Darüber hinaus legen wir großen Wert auf die Details und arbeiten mit Ihnen zusammen, um sicherzustellen, dass Ihr Hochzeits-Look perfekt zu Ihrem Kleid, Ihrem Schmuck und Ihrem Gesamtkonzept passt. Wir verwenden nur hochwertige Produkte und Techniken, um sicherzustellen, dass Ihr Styling den ganzen Tag über hält und Ihnen ein strahlendes Aussehen verleiht.
        
Bei Friseur Katharinas möchten wir dazu beitragen, dass Ihr Hochzeitstag zu einem unvergesslichen Erlebnis wird. Kontaktieren Sie uns noch heute, um einen Beratungstermin zu vereinbaren, und lassen Sie uns gemeinsam Ihren Traumlook für Ihren besonderen Tag verwirklichen.`,
  },
  {
    title: "Makeup",
    image: service6,
    description:
      "Friseur Katharinas bietet nicht nur exzellente Haarpflege- und Styling-Dienstleistungen an, sondern verfügt auch über ein talentiertes Team von Make-up-Künstlern, die sich darauf spezialisiert haben, das natürliche Schönheitspotential jedes Kunden zu betonen und zu verbessern. Von glamourösen Abendlooks bis hin zu subtilen Tagesmake-ups beherrschen die Make-up-Künstler von Friseur Katharinas eine Vielzahl von Techniken und Stilen, um die individuellen Vorlieben und Persönlichkeiten ihrer Kunden widerzuspiegeln. Mit hochwertigen Produkten und einer fundierten Kenntnis der neuesten Trends und Techniken können Kunden sicher sein, dass sie bei Friseur Katharinas eine maßgeschneiderte und professionelle Make-up-Erfahrung erhalten. Egal, ob es sich um eine besondere Veranstaltung handelt oder einfach nur darum geht, sich selbst zu verwöhnen, das Make-up-Team von Friseur Katharinas steht bereit, um jedem Kunden dabei zu helfen, sein Strahlen zu verstärken und sich selbstbewusst und schön zu fühlen.",
  },
  {
    title:"Lashes",
    image: service7,
    description:
      `Friseur Katharina ist weit mehr als nur ein herkömmlicher Friseursalon. Hier wird nicht nur exzellente Haarpflege angeboten, sondern auch Wimperndienstleistungen auf höchstem Niveau. Mit einem Team von erfahrenen Wimpernstylisten, die über ein umfangreiches Know-how und eine Leidenschaft für perfekte Wimpern verfügen, können Kunden eine Transformation ihres Aussehens erleben.

Das Wimpernangebot von Friseur Katharina umfasst eine breite Palette von Techniken und Stilen, die darauf abzielen, die natürliche Schönheit zu betonen und den Blick zu öffnen. Von klassischen Wimpernverlängerungen bis hin zu voluminösen Wimpernverdichtungen und allem dazwischen, gibt es für jeden Kunden die perfekte Option.
      
Was Friseur Katharina von anderen Salons unterscheidet, ist die Hingabe zum Detail und die Verwendung hochwertiger Produkte. Jeder Schritt des Prozesses wird sorgfältig durchgeführt, um sicherzustellen, dass das Endergebnis nicht nur ästhetisch ansprechend ist, sondern auch den höchsten Standards entspricht.
      
Egal, ob es sich um einen besonderen Anlass handelt oder einfach darum geht, das Selbstbewusstsein zu steigern, das Wimpern-Team von Friseur Katharina steht bereit, um jeden Kunden mit einem atemberaubenden Wimpern-Look zu begeistern. Hier geht es nicht nur darum, Wimpern anzubringen, sondern vielmehr darum, eine Erfahrung zu schaffen, die jeden Kunden strahlen lässt.`,
  },
  {
    title:"Haircut",
    image: service8,
    description:
      `Friseur Katharina steht für herausragendes Friseurhandwerk und erstklassige Haarschnitte, die die individuelle Schönheit jedes Kunden unterstreichen. Mit einem Team von erfahrenen Friseuren, die über ein umfassendes Know-how und eine Leidenschaft für ihr Handwerk verfügen, ist Friseur Katharina der Ort, an dem Kunden eine persönliche und professionelle Betreuung erhalten.

Vom klassischen Haarschnitt bis hin zu trendigen Stylings beherrscht das Team von Friseur Katharina eine Vielzahl von Techniken und Stilen, um die Bedürfnisse und Wünsche jedes Kunden zu erfüllen. Durch eine gründliche Beratung und individuelle Aufmerksamkeit wird jeder Haarschnitt bei Friseur Katharina zu einem maßgeschneiderten Erlebnis, das den Kunden einzigartig macht.
      
Was Friseur Katharina auszeichnet, ist die Liebe zum Detail und die Verwendung hochwertiger Produkte. Jeder Haarschnitt wird mit Präzision und Sorgfalt durchgeführt, um sicherzustellen, dass das Endergebnis nicht nur ästhetisch ansprechend ist, sondern auch den höchsten Standards entspricht.
      
Egal, ob es sich um eine kleine Veränderung oder eine komplette Transformation handelt, das Friseur-Team von Friseur Katharina steht bereit, um jeden Kunden mit einem individuellen und professionellen Haarschnitt zu begeistern. Hier geht es nicht nur darum, Haare zu schneiden, sondern vielmehr darum, eine Erfahrung zu schaffen, die jeden Kunden strahlen lässt.`,
  },
];

const Modal = ({ isOpen, onClose, title, image, description }) => {
  if (!isOpen) return null;

  // Close modal when clicking outside of the modal content
  const handleOverlayClick = (e) => {
    if (e.target.id === "modal-overlay") {
      onClose();
    }
  };

  return (
    <div
      id="modal-overlay"
      className="fixed inset-0 z-50 bg-black bg-opacity-50 flex justify-center items-start md:items-center pt-20 md:pt-0" // Adjust z-index and padding-top
      onClick={handleOverlayClick}
    >
      <div
        className="bg-white p-4 rounded-lg max-w-4xl w-full max-h-[80vh] overflow-y-auto mt-4 md:mt-0 mx-2" // Add margins for mobile view
      >
        <h2 className="text-2xl font-bold">{title}</h2>
        <img src={image} alt={title} className="rounded my-4 w-20 h-20" />
        <div className="prose max-w-none">
          <p>
            <pre>{description}</pre>
          </p>
        </div>
        <button
          className="mt-4 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700 transition duration-300"
          onClick={onClose}
        >
          Schließen
        </button>
      </div>
    </div>
  );
};

const ServiceCard = ({ title, image, description, onReadMoreClick }) => {
  return (
    <div className="w-full bg-white p-4 flex flex-col items-center border border-lightpink">
      <img src={image} alt={title} className="rounded-full w-20 h-20" />
      <h1 className="text-center text-2xl font-bold py-1">{title}</h1>
      <p className="text-gray-500 text-center line-clamp-3">{description}</p>
      <button
        className="mt-4 text-sm px-2 py-1 border border-gray-200 bg-gray-100 text-gray-400 rounded hover:bg-lightpink hover:text-white transition duration-300"
        onClick={onReadMoreClick}
      >
        Mehr lesen
      </button>
    </div>
  );
};

export const ServiceSection = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedService, setSelectedService] = useState(null);

  const handleReadMoreClick = (service) => {
    setSelectedService(service);
    setIsModalOpen(true);
  };

  return (
    <div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2 lg:gap-4">
        {services.map((service, index) => (
          <div key={index} className="px-2 py-4 lg:p-4">
            <ServiceCard
              {...service}
              onReadMoreClick={() => handleReadMoreClick(service)}
            />
          </div>
        ))}
      </div>
      {selectedService && (
        <Modal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          {...selectedService}
        />
      )}
    </div>
  );
};
