import React from "react";

const TimingsSection = () => {
  const schedules = [
    { day: "Dienstag", hours: "9 Uhr - 17:30 Uhr" },
    { day: "Mittwoch", hours: "9 Uhr - 17:30 Uhr" },
    { day: "Donnerstag", hours: "9 Uhr - 17:30 Uhr" },
    { day: "Freitag", hours: "10 Uhr - 20 Uhr" },
    { day: "Samstag", hours: "8 Uhr - 13 Uhr" },
  ];

  return (
    <div className="container mx-auto px-4 py-12">
      <h1 className="text-4xl font-bold mb-4 md:mb-0 mr-0 md:mr-4">Zeitplan</h1>
      <div className="flex flex-col sm:flex-row items-center sm:items-start py-4 flex-wrap">
        {schedules.map((schedule, index) => (
          <div
            key={index}
            className="min-w-[180px] md:min-w-[150px] bg-lightpink text-white rounded-tr-xl p-4 m-2 flex flex-col items-center justify-center hover:bg-pink-600 space-y-2"
          >
            <i className="far fa-clock text-lg"></i>
            <span className="font-bold">{schedule.day}</span>
            <span>{schedule.hours}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TimingsSection;
