import "./TeamBanner.css";
import teamImg from "../../../Assets/team.jpg";
export const TeamBanner = () => (
  <div className="bg-lightpink">
    <div className="container mx-auto px-4">
      <div className="flex flex-col md:flex-row justify-center gap-8">
        <div className="w-full md:w-6/12 flex justify-center relative">
          <div>
            <img
              src={teamImg}
              alt=""
              className="w-full teambanner"
              sizes="(max-width: 768px) 100vw, 768px"
            />
          </div>
        </div>
        <div className="w-full md:w-6/12 flex items-center py-8">
          <div className="text-center lg:text-left">
            <h1 className="text-4xl font-bold mb-4">Wer wir sind?</h1>
            <p className="text-base text-white font-normal">
              Hallo und herzlich willkommen bei uns!<br></br>
              Mein Name ist Katharina und ich bin stolz darauf, eine
              ausgebildete Friseurmeisterin zu sein. Zusammen mit meinem
              erfahrenen Team stehen wir bereit, um deine Frisurenträume
              Wirklichkeit werden zu lassen. Dank unserer langjährigen Erfahrung
              und unserer Leidenschaft für das Friseurhandwerk können wir uns
              voll und ganz auf deine Wünsche und Bedürfnisse konzentrieren. Wir
              streben stets nach höchster Qualität und möchten sicherstellen,
              dass du mit deinem Styling auf höchstem Niveau zufrieden bist.
              Komm vorbei und lass dich von unserem talentierten Team verwöhnen
              – wir freuen uns darauf, dich strahlen zu sehen!
            </p>
            <p className="font-bold text-uppercase mt-4 tetx-lg text-pink-700">
              Katharina // Eigentümer Friseur
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
);
