import { Helmet } from "react-helmet";

export const HelmetSEO = ({ title, description, url }) => (
  <div>
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={url} />
      {/* Dynamic Open Graph Tags */}
      <meta property="og:type" content="website"></meta>
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={url} />

      <meta property="twitter:card" content="summary_large_image" />
      <meta
        property="twitter:url"
        content={url} 
      />
      <meta
        property="twitter:title"
        content={title}
      />
      <meta
        property="twitter:description"
        content={description} 
      />

      <meta
        name="keywords"
        content="friseur, Friseur Dortmund,friseur,Haarverlängerung,tressen,Friseurkatharinas,toupet, Friseur Katharinas Dortmund, Friseursalon Dortmund Benninghofen, engagiertes Friseurteam, Haarstyling, Haarfärbung, Strähnen Extensions, hochwertige Friseurprodukte, Friseurschulungen Weiterbildungen."
      ></meta>
    </Helmet>
  </div>
);
