import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import logo from "../../Assets/logo.jpg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { BookAppointment } from "../Sections/Button/Button";

export default function Navbar({ fixed }) {
  const [navbarOpen, setNavbarOpen] = useState(false);
  const navbarRef = useRef(null);

  const handleNavClick = (e) => {
    // Check if the clicked element is a link or inside a link
    if (e.target.closest("a")) {
      setNavbarOpen(false);
    }
  };

  return (
    <>
      <div className="sticky top-0 z-50  bg-lightpink gap-2 text-white py-3 px-4">
        <div className="container mx-auto max-w-4xl flex items-center">
          <ul className="flex flex-col sm:flex-row gap-4 sm:gap-12">
            <li className="flex items-center gap-2">
              <i class="fa-solid fa-location-dot"></i>
              <p className="text-xs sm:text-base ">
                Benninghofer Str. 151, 44269 Dortmund, Germany
              </p>
            </li>
            <li className="flex items-center gap-2">
              <i class="fa-solid fa-phone-flip"></i>
              <a href="tel:+4923146241" className="text-xs sm:text-base">
                +49 231 462411
              </a>
            </li>
          </ul>
        </div>
      </div>
      <nav
        ref={navbarRef}
        className=" mx-auto sticky top-[72px] sm:top-[48px] z-50 flex flex-wrap justify-between bg-white lg:px-4 shadow-lg"
      >
        <div
          className="container max-w-4xl w-full mx-auto flex flex-col lg:flex-row justify-between"
          onClick={handleNavClick}
        >
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start px-2">
            <div className="flex flex-col">
              <a
                className="text-sm font-bold leading-relaxed inline-block whitespace-nowrap uppercase py-1"
                href="/"
              >
                <img src={logo} className="w-auto h-16"></img>
              </a>
            </div>
            <button
              className="text-black cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              {navbarOpen ? (
                <i class="fas fa-times text-black"></i>
              ) : (
                <i className="fas fa-bars text-black"></i>
              )}
            </button>
          </div>
          <div
            className={
              "lg:flex items-center justify-start" +
              (navbarOpen ? " flex" : " hidden")
            }
            id="example-navbar-danger"
          >
            <ul className="w-full flex flex-col lg:flex-row list-none lg:ml-auto items-center justify-start h-full ">
              <li className="nav-item mb-4 lg:mb-0">
                <Link
                  to="/services"
                  className="link px-6 py-2 text-md font-bold leading-snug hover:text-lightpink"
                >
                  Dienstleistungen
                </Link>
              </li>

              <li className="nav-item mb-4 lg:mb-0">
                <Link
                  to="/about-us"
                  className="link px-6 py-2 text-md font-bold leading-snug  hover:text-lightpink"
                >
                  Über uns
                </Link>
              </li>

              <li className="nav-item mb-4 lg:mb-0">
                <Link
                  to="/events"
                  className="link px-6 py-2 text-md font-bold leading-snug  hover:text-lightpink"
                >
                  Veranstaltungen
                </Link>
              </li>

              <li className="nav-item mb-4 lg:mb-0">
                <Link
                  to="/gallery"
                  className="link px-6 py-2 text-md font-bold leading-snug  hover:text-lightpink"
                >
                  Galerie
                </Link>
              </li>

              <li className="nav-item mb-4 lg:mb-0">
                <Link
                  to="/contact-us"
                  className="link px-6 py-2 text-md font-bold leading-snug  hover:text-lightpink"
                >
                  Kontaktiere uns
                </Link>
              </li>
            </ul>
          </div>
          <div className="flex">
            <Link
              to="https://www.time-globe-crs.de/crs-online-7354/"
              target="_blank"
              className="w-full h-12 my-auto relative bg-pink-600 text-white font-bold hover:bg-pink-700 flex items-center justify-center group"
            >
              <span className="w-full flex-auto text-center px-4">
                Einen Termin Vereinbaren
              </span>
              <span className="h-full right-0 inset-y-0 bg-pink-700 flex items-center justify-center w-16 group-hover:bg-pink-800">
                <i className="fas fa-chevron-right text-white"></i>
              </span>
            </Link>
          </div>
        </div>
      </nav>
    </>
  );
}
