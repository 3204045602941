import { useState, useEffect } from "react";

function Footer() {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  useEffect(() => {
    // Update the year every time the component mounts
    setCurrentYear(new Date().getFullYear());
  }, []); // Empty dependency array ensures it runs only once on mount

  return (
    <footer className="bg-gray-900 text-white p-4 md:p-10">
      <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-4 gap-8 text-center md:text-left">
        <div>
          <h2 className="text-4xl font-bold mb-4">Friseur.</h2>
          <p className="mb-4">
            <i className="fas fa-phone-alt mr-2"></i>
            <a href="tel:+4923146241" className="text-xs sm:text-base">
              +49 231 462411
            </a>
          </p>
          <p className="mb-4">
            <i className="fas fa-map-marker-alt mr-2"></i>Benninghofer Str. 151,
            44269 Dortmund, Germany
          </p>
        </div>
        <div>
          <h1 className="font-bold text-lg mb-4">Zeitangaben</h1>

          <p className="pb-2">
            <span className="text-pink-600 mr-2">Montag: </span> Ruhetag!
          </p>
          <p className="pb-2">
            <span className="text-pink-600 mr-2">Dienstag: </span> 9:00 Uhr bis
            17.30 Uhr
          </p>
          <p className="pb-2">
            <span className="text-pink-600 mr-2">Mittwoch: </span> 9:00 Uhr bis
            17.30 Uhr
          </p>
          <p className="pb-2">
            <span className="text-pink-600 mr-2">Donnerstag: </span> 9:00 Uhr
            bis 17:30 Uhr
          </p>
          <p className="pb-2">
            <span className="text-pink-600 mr-2">Freitag: </span> 10:00 Uhr bis
            20:00 Uhr
          </p>
          <p className="pb-2">
            <span className="text-pink-600 mr-2">Samstag: </span> 8:00 Uhr bis
            13:00 Uhr
          </p>
        </div>
        <div>
          <h1 className="font-bold text-lg mb-4">Soziale Links</h1>
          <div className="flex gap-4 justify-center md:justify-start">
            <a
              href="https://www.facebook.com/katharina.sunshine.9"
              className="text-pink-600"
            >
              <i className="fab fa-facebook-f"></i>
            </a>
            <a
              href="https://www.instagram.com/friseur_katharinas/"
              className="text-pink-600"
            >
              <i className="fab fa-instagram"></i>
            </a>
            <a
              href="https://www.tiktok.com/@friseur_katharinas"
              className="text-pink-600"
            >
              <i className="fab fa-tiktok"></i>
            </a>
            <a
              href="mailto:katharina150689@hotmail.com"
              className="text-pink-600"
            >
              <i className="fas fa-envelope"></i>
            </a>
          </div>
        </div>
        <div>
          <h2 className="font-bold text-lg mb-4">Seiten</h2>
          <ul>
            <li className="mb-2">
              <a className="hover:underline" href="/services">
                Dienstleistungen
              </a>
            </li>
            <li className="mb-2">
              <a className="hover:underline" href="/about-us">
                Über uns
              </a>
            </li>
            <li className="mb-2">
              <a className="hover:underline" href="/events">
                Veranstaltungen
              </a>
            </li>
            <li className="mb-2">
              <a className="hover:underline" href="/gallery">
                Galerie
              </a>
            </li>
            <li className="mb-2">
              <a className="hover:underline" href="/contact-us">
                Kontaktiere uns
              </a>
            </li>
            <li className="mb-2">
              <a className="hover:underline" href="/imprint">
                Impressum
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="border-t border-gray-700 mt-8 pt-8">
        <p className="text-center text-sm">
          &copy; Urheberrecht 2024. Alle Rechte vorbehalten
        </p>
        <p className="text-center text-sm mt-4">
          Website made by{" "}
          <a href="https://www.fiverr.com/s/DYAWva" className="underline">Sufiyan Irfan</a>
        </p>
      </div>
    </footer>
  );
}
export default Footer;
