import { BookAppointment, FreeEstimateButton } from "../Button/Button";
import "./TakeCare.css";

export const TakeCare = () => (
  <div className="min-w-full h-[320px] md:h-[450px] lg:h-[500px] banner3 flex justify-center items-center">
    <section className="mx-auto container py-8 sm:py-16 px-2 relative z-10">
      <div className="max-w-xl space-y-12">
        <h1 className="text-3xl md:text-5xl text-white font-bold">
        Wir kümmern uns von Anfang bis Ende um Sie
        </h1>
        <BookAppointment />
      </div>
    </section>
  </div>
);
