import React from "react";
import { useParams } from "react-router-dom";
import { TakeCare } from "../Sections/TakeCare/TakeCare";
import parse from "html-react-parser";
import work1 from "../../Assets/work/work1.jpg";
import bride2 from "../../Assets/bride/bride2.jpeg";
import hair3 from "../../Assets/hair/hair3.jpg"

const articleData = [
  {
    url: "revitalize-your-style-regular-salon-visits",
    title: "Revitalize Your Style: The Essence of Regular Salon Visits",
    date: "February, 15 2024",
    readTime: "5 Min Read",
    imageUrl: work1,
    description: ` <p>
    Just as a well-maintained home requires regular care, your hair deserves consistent attention to maintain its health and vibrancy. Haircuts, treatments, and styling are not just luxuries; they're essential practices for personal upkeep and image. The question then arises: how often should you indulge in these hair care rituals?
</p>

<h2 class="mt-8 font-bold text-2xl mb-2">
    The Vitality of a Trim and Treatment
</h2>
<p>
    Before we delve into a routine, let’s grasp why consistent salon visits are crucial. Regular trims keep split ends at bay, ensuring your hair not only looks great but also grows healthily. Salon treatments, on the other hand, can address specific concerns such as dryness, damage, and scalp health. When neglected, these issues can lead to lackluster hair and stunted growth.
</p>

<h2 class="mt-8 font-bold text-2xl mb-2">
    Personalized Hair Care Schedules
</h2>
<p>
    A general guideline suggests a salon visit every 4-6 weeks for a trim, but this varies depending on hair type, style, and personal growth rates. Treatments like coloring or deep conditioning may have different intervals. For those with color-treated hair or unique conditions, a tailored schedule created with your stylist will ensure optimal care.
</p>

<h2 class="mt-8 font-bold text-2xl mb-2">
    Indicators Your Hair Needs Attention
</h2>
<p>
    Your hair communicates its needs in various ways. Excessive tangling, noticeable split ends, or a lackluster appearance can signal it’s time for a salon visit. Similarly, if your scalp feels off-balance or if your style no longer sits right, these are your cues to book an appointment.
</p>

<h2 class="mt-8 font-bold text-2xl mb-2">
    The Perks of Professional Styling
</h2>
<p>
    While home care is essential, the expertise of a seasoned stylist cannot be replicated. A professional cut ensures precision and style, while salon-grade treatments use high-quality products that offer a level of efficacy beyond over-the-counter options. For those not versed in hair care techniques, salon visits are a worthwhile investment.
</p>

<h2 class="mt-8 font-bold text-2xl mb-2">
    Preserving Salon Perfection
</h2>
<p>
    Between salon visits, maintain the health of your hair with recommended products and minimal heat styling. Regular brushing and proper hydration can prolong the fresh-salon feel. Additionally, consider periodic consultations with your stylist to adjust your hair care regimen as needed.
</p>

<h2 class="mt-8 font-bold text-2xl mb-2">
    Embrace the Salon Experience
</h2>
<p>
    In conclusion, making regular appointments at your hair salon is key to sustaining healthy, stylish hair. By collaborating with your stylist, you can create a personalized care schedule that ensures your hair remains in prime condition. Remember, investing in regular salon visits is not just indulgence—it's a commitment to your personal well-being and confidence.
</p>`,
  },
  {
    url: "bridal-makeup-crafting-your-wedding-look",
    title: "Bridal Makeup: Crafting Your Perfect Wedding Day Look",
    date: "February, 19 2024",
    readTime: "5 Min Read",
    imageUrl: bride2,
    description: ` <p>
    Your wedding day is a once-in-a-lifetime event, and every bride deserves to look and feel her absolute best. Bridal makeup is not just about applying cosmetics; it's about creating a look that reflects your personality and style, enhances your natural beauty, and lasts throughout your special day.
</p>

<h2 class="mt-8 font-bold text-2xl mb-2">Personalizing Your Bridal Style</h2>
<p>
    The first step in achieving your dream wedding look is a personalized makeup trial. This session with your makeup artist is crucial for discussing your preferences, testing out products, and ensuring that your vision becomes a reality.
</p>

<h2 class="mt-8 font-bold text-2xl mb-2">Timeless Elegance or Contemporary Chic</h2>
<p>
    Whether you envision a classic and timeless appearance or a modern and trendy vibe, the right makeup artist will bring your bridal look to life. From airbrushing to traditional techniques, there's a myriad of methods to suit your desired aesthetic.
</p>

<h2 class="mt-8 font-bold text-2xl mb-2">The Importance of High-Quality Products</h2>
<p>
    On your wedding day, the quality of your makeup is as important as the skill of your makeup artist. High-quality products not only enhance your beauty but also provide comfort and longevity, ensuring you look flawless from the ceremony to the last dance.
</p>

<h2 class="mt-8 font-bold text-2xl mb-2">Skincare Preparation for the Big Day</h2>
<p>
    A radiant bridal makeup look starts with good skin. In the months leading up to your wedding, adopt a skincare routine that will provide the perfect canvas for your makeup. Hydration, exfoliation, and regular facials can make a significant difference.
</p>

<h2 class="mt-8 font-bold text-2xl mb-2">Makeup That Lasts</h2>
<p>
    Your wedding day will be filled with moments that take you from morning to midnight. Your makeup should be designed to last, with attention to detail that includes waterproof products and setting techniques to combat any tears of joy or celebratory toasts.
</p>

<h2 class="mt-8 font-bold text-2xl mb-2">Conclusion</h2>
<p>
    Bridal makeup is an art that complements the joy of your wedding day. It's the final touch that completes your bridal attire and makes you feel like the star of your own fairytale. With the right preparation and expert touch, your wedding day makeup will be as memorable as the day itself.
</p>`,
  },
  {
    url: "hair-styling-and-cutting",
    title: "Hair Styling and Cutting: Transforming Your Look with Precision and Artistry",
    date: "February, 25 2024",
    readTime: "5 Min Read",
    imageUrl: hair3,
    description: ` <p>
    The right haircut and style can define your look, complement your features, and reflect your individuality. Whether you're after a subtle trim or a dramatic change, a professional hair stylist can craft a cut that not only looks fantastic but also works with your lifestyle and personal taste.
</p>

<h2 class="mt-8 font-bold text-2xl mb-2">The Art of Hair Design</h2>
<p>
    A great haircut is the foundation of any style. It can frame your face, enhance your best features, and even minimize the time you spend styling each day. Your stylist will consider your hair's natural texture, growth patterns, and your styling routine when creating your custom cut.
</p>

<h2 class="mt-8 font-bold text-2xl mb-2">Trends vs. Timelessness</h2>
<p>
    While keeping up with hair trends can be exciting, it's important to consider what suits you personally. A skilled stylist can help you find a balance between trendy and timeless, ensuring your look is both current and flattering.
</p>

<h2 class="mt-8 font-bold text-2xl mb-2">Cutting Techniques for Every Hair Type</h2>
<p>
    Different hair types require different cutting techniques. From layering to create movement and volume in fine hair, to precision cuts that shape and define thick or curly hair, your stylist will use techniques that bring out the best in your hair type.
</p>

<h2 class="mt-8 font-bold text-2xl mb-2">Maintaining Your Cut</h2>
<p>
    To keep your hair looking its best, regular trims are essential. They help maintain the shape of your cut and keep split ends at bay. Your stylist can recommend a maintenance schedule that keeps your hair healthy and your style looking fresh.
</p>

<h2 class="mt-8 font-bold text-2xl mb-2">Styling for Special Occasions</h2>
<p>
    Special events call for special hairstyles. Whether it's an elegant updo, romantic waves, or a sleek blowout, a professional stylist can create a look that complements your outfit and the occasion.
</p>

<h2 class="mt-8 font-bold text-2xl mb-2">Conclusion</h2>
<p>
    A haircut is more than just a change in length; it's a form of self-expression and a boost to your confidence. Whether you're preparing for a special event or just need to refresh your everyday style, a professional hair stylist can help you look and feel your best.
</p>`,
  },
];

export const BlogPage = () => {
  const { blogUrl } = useParams();
  const article = articleData.find(
    (article) => `/blog/${article.url}` === `/blog/${blogUrl}`
  );
  console.log(article);
  const { title, date, imageUrl, description } = article;

  return (
    <>
      <div className="bg-gray-100 pb-16">
        <div className="container max-w-4xl mx-auto p-4">
          {/* Blog Header */}
          <header className="text-center my-10">
            <h1 className="text-4xl font-bold text-pink-600 leading-tight mb-4">
              {title}
            </h1>
            <p className="text-lg text-gray-600">By Friseur | {date}</p>
          </header>

          {/* Featured Image */}
          <div className="flex items-center justify-center">
            <img
              src={imageUrl}
              alt="Gutters"
              className="max-w-4xl w-full h-auto max-h-[800px] rounded-lg shadow-lg mb-8 object-cover"
            />
          </div>

          {/* Blog Content */}
          <article className="prose lg:prose-lg prose-green mx-auto">
            {parse(description)}
          </article>
        </div>
      </div>
      <TakeCare />
    </>
  );
};
